import React, { useState, useEffect } from "react"
import Layout from "../../components/layout"
import HubspotForm from "react-hubspot-form"
import { Typewriter } from "react-simple-typewriter"
// import RewardPoints from "../../components/RewardPoints"
import Flipper from "../../components/Flipper"
import AOS from "aos"
import "aos/dist/aos.css"
import Helmet from "react-helmet"
import { useLanguages } from '../../hooks/useLanguages'
import RewardCatalogSlideUp from "../../components/RewardCatalogSlideUp"

// function deferredScroll(sectionID) {
//     document.getElementById(sectionID).addEventListener('click', function(event) {

//       // Prevent default behavior of anchor tag
//       event.preventDefault();
      
//       // Get the target section by its ID
//       var targetSection = document.getElementById(this.getAttribute('data-set').substring(1));
//       console.log("target", targetSection);
  
//       // Scroll to the target section smoothly
//       targetSection.scrollIntoView({ behavior: 'smooth' });
//     });
// }

const contents = [
  {
      tabName: '',
      imageMobilePaddingBottom: 'pb-0',
      mobileImageQuality: 'w_400',
      heading: 'Standard of Living Index (SOLI) &nbsp;',
      paragraph: 'SOLI-based reward system to ensure fair adjustments of reward points for the global workforce',
      image: '2024/07/Rewards-SOLI.webp',
      knowMoreLink: '',
      tabHeightMobile: 'h-96',
       desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/Rewards-SOLI.webp",
        mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/Rewards-SOLI.webp"
  },
  {
      tabName: '',
      imageMobilePaddingBottom: 'pb-0',
      mobileImageQuality: 'w_400',
      heading: 'Mobile App &nbsp;',
      paragraph: 'Responsive and easy-to-use mobile app for the on-the-go recognition & rewards',
      image:'2024/07/Rewards-Mobilefirst.webp',
      knowMoreLink: '',
      tabHeightMobile: 'h-96',
      desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/Rewards-Mobilefirst.webp",
      mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/Rewards-Mobilefirst.webp"
  },
  {
      tabName: '',
      imageMobilePaddingBottom: 'pb-0',
      mobileImageQuality: 'w_400',
      heading: 'No mark up for reward points &nbsp;',
      paragraph: 'Enjoy the true worth of Gift Cards at face value with zero markup',
      image: '2024/07/Rewards-Redemption.webp',
      knowMoreLink: '',
      tabHeightMobile: 'h-96',
      desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/Rewards-Redemption.webp",
      mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/Rewards-Redemption.webp"
  },
  {
      tabName: '',
      imageMobilePaddingBottom: 'pb-0',
      mobileImageQuality: 'w_400',
      heading: 'AIR<sup>e</sup> &nbsp;',
      paragraph: 'Assess and design R&R strategy with our proprietary AIRᵉ Framework',
      image:'2024/07/Rewards-AIRe.webp',
      knowMoreLink: '',
      tabHeightMobile: 'h-96',
      desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/Rewards-AIRe.webp",
      mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/Rewards-AIRe.webp"
  },
  {
      tabName: '',
      imageMobilePaddingBottom: 'pb-0',
      mobileImageQuality: 'w_400',
      heading: 'Languages &nbsp;',
      paragraph: 'Available in 16+ languages for your global workforce',
      image:'2024/07/Rewards-Multi-lingual.webp',
      knowMoreLink: '',
      tabHeightMobile: 'h-96',
      desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/Rewards-Multi-lingual.webp",
      mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/Rewards-Multi-lingual.webp"
  },
  {
      tabName: '',
      imageMobilePaddingBottom: 'pb-0',
      mobileImageQuality: 'w_400',
      heading: 'Greetings &nbsp;',
      paragraph: 'Custom online Greetings to bring teams closer on special occasions',
      image:'2024/07/Rewards-Greetings.webp',
      knowMoreLink: '',
      tabHeightMobile: 'h-96',
      desktopImage:"https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/07/Rewards-Greetings.webp",
      mobileImage: "https://res.cloudinary.com/vantagecircle/image/upload/w_350/q_auto/gatsbycms/uploads/2024/07/Rewards-Greetings.webp"
  },
]

const rewardPointProp = {
  sectionHeading: "REWARDS CATALOG",
  sectionHeadingFirst: 'Enhance the employee reward experience with our',
  sectionHeadingSecond: "Global Rewards Catalog",
  headingClass: "text-orange",
  slideDetails : [{
    img:
      "/2024/02/Rewards-Catalog-Experience.png",
    parentClass:
      "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
    class:
      "xl:rounded-l-xl relative transform scale-105 hover:scale-110 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
    heading: "EXPERIENCES",
    headingClass:
      "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
    description:
      "Experiences are our experiential offerings for your employees. From cycling tours for sports enthusiasts to vineyard tours for wine connoisseurs, from relaxing and luxurious hotel stays to trekking and camping in nature's lap, reward your employees with lifetime experiences to create everlasting memories.",
  },
  {
    img:
      "/2024/02/Rewards-Catalog-Merchandise.png",
    parentClass:
      "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
    class:
      "relative transform hover:scale-110 scale-105 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
    heading: "MERCHANDISE",
    headingClass:
      "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
    description:
      "Merchandise offers an all-inclusive solution for point redemption, featuring a diverse range of products from top brands, delivered directly to employees. This eliminates the hassle of navigating various merchant sites ensuring a smooth experience and allowing preferred organizational vendors to be onboarded.",
  },
  {
    img:
      "/2024/02/Rewards-Catalog-Gifting.png",
    parentClass:
      "relative overflow-hidden cursor-pointer rewardCatalogCardHeight",
    class:
      "relative transform hover:scale-110 scale-105 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
    heading: "GIFT CARDS",
    headingClass:
      "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
    description:
      "Gift cards, a perennial favorite, offer a simple and accessible way to redeem reward points. Employees can easily exchange Vantage Points for Gift Cards, available in various denominations in 70+ countries, from various global brands across fashion, travel, entertainment, groceries, and more.",
  },
  {
    img:
      "/2024/02/Rewards-Catalog-Amazon-business.png",
    parentClass:
      "relative grid xl:items-end xl:block overflow-hidden cursor-pointer rewardCatalogCardHeight",
    class:
      "xl:rounded-r-xl relative transform scale-105 hover:scale-110 -translate-y-64 hover:-translate-y-52 md:hover:-translate-y-2 xl:hover:-translate-y-16 md:translate-y-0 duration-300 w-full object-cover",
    heading: "AMAZON BUSINESS",
    headingClass:
      "w-full xl:w-530 font-bold text-3xl md:text-3xl xl:text-4xl absolute left-6 top-20 md:top-16 md:left-20 md:-bottom-10 lg:left-32 xl:-left-20 xl:bottom-40 text-white transform md:-rotate-90 pointer-events-none",
    description:
      "Amazon Store, our remarkable integration with Amazon business allows your employees to exchange their reward points for 10M+ Amazon products effortlessly. With just a few clicks, they can have their chosen products delivered to their preferred address with express Prime shipping.",
  }
]
}


const RewardsRecognition = ({ location, abVersion }) => {
  const [image, setImage] = useState(
    "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Rewards-Dashboardanalytics.png"
  )

  useEffect(() => {
    typeof window !== undefined &&
      window.innerWidth < 769 &&
      setImage(
        "https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Rewards-dashboard-mobile.png.webp"
      )
    AOS.init({
      once: true,
    })
  }, [])

  const [currLanguage] = useLanguages()

  return (
    <Layout location={location} pageLanguage={currLanguage}>
      <Helmet>
        <title>
          Employee Rewards and Recognition Program | Vantage Rewards
        </title>

        <meta
          name="description"
          content="The modern employee rewards and recognition program that enables organizations to reward their employees, along with recognition for hard work, major accomplishments, and more."
        />
        <meta
          property="twitter:title"
          content="Employee Rewards and Recognition Program | Vantage Rewards"
        />
        <meta name="robots" content="index" />

        <meta
          property="twitter:description"
          content="The modern employee rewards and recognition program that enables organizations to reward their employees, along with recognition for hard work, major accomplishments, and more."
        />

        <meta property="og:type" content="website" />

        <meta
          property="og:url"
          content="https://www.vantagecircle.com/products/rewards-recognition/"
        />

        <meta property="og:site_name" content="Vantage Circle" />
        <meta
          property="og:title"
          content="Employee Rewards and Recognition Program | Vantage Rewards"
        />

        <meta
          property="og:description"
          content="The modern employee rewards and recognition program that enables organizations to reward their employees, along with recognition for hard work, major accomplishments, and more."
        />
        <link
          rel="canonical"
          href="https://www.vantagecircle.com/products/rewards-recognition/"
        ></link>
      </Helmet>

      <section
        id="watchVideoClass"
        class="w-11/12 2xl:max-w-7xl mx-auto rounded-xl flex place-content-center relative bg-purple-80 overflow-x-hidden pt-9 xl:py-0 overflow-y-hidden z-0 px-3 lg:px-0"
      >
        <div className="w-full grid lg:grid-cols-2 justify-center items-start z-10 lg:px-10 relative">
          <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 xl:pt-20 xl:pb-20">
            <div className="my-2 flex justify-center items-center">
              <h2 className="lg:pl-2 text-center lg:text-left heroTagStyle">
                REWARDS & RECOGNITION
              </h2>
            </div>
            <h1 class="text-newOrange lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-6xl text-center lg:text-left relative">
              <Typewriter
                words={["Reward", "Recognize", "Motivate", "Engage"]}
                loop={0}
                cursor
                cursorStyle="|"
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={1000}
              />
            </h1>
            <h1 className="lg:pr-3 xl:w-full text-3xl md:text-4xl xl:text-6xl text-center lg:text-left text-indigo-100">
              Your Workforce.
            </h1>
            <p
              className="my-8 text-indigo-100 text-center lg:text-left w-full"
              style={{ lineHeight: "2rem" }}
            >
              Boost productivity and team performance with our easy-to-use and
              mobile-friendly{" "}
              <span className="font-bold">
                Rewards and Recognition platform.
              </span>
            </p>
            <div className="xl:flex justify-center items-center lg:justify-start w-full md:w-1/2 lg:w-10/12 2xl:w-full cursor-pointer mb-8 z-10">
              
              <a 
                className="vc-new-orange-btn-rounded-full text-white lato rounded-full mb-3 xl:mb-0 mx-auto xl:ml-0 xl:mr-2" href="#rewardsForm" id="gtm_cta_r&rproductpage_demobtn"
              >
                Book a product demo
              </a>

              <button className="youtube-video-btn vc-new-indigo-border-btn-rounded-full text-indigo-500 lato rounded-full mt-2 xl:mt-0 xl:ml-2 modal-open modal-toggle">
                Watch video
              </button>

            </div>
          </div>
          <div className="lg:absolute md:-bottom-64 md:scale-90 lg:scale-75 lg:transform xl:scale-100 md:right-5 lg:-bottom-20 lg:-right-20 xl:-bottom-16 xl:right-0 flex justify-center items-center lg:h-full mt-6 pt-4 xl:mt-0 lg:ml-3">
            <picture>
              <source
                className="z-10 relative"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Rewards-Hero-main.png"
                type="image/webp"
                alt="Vantage Rewards"
                width="600"
              />
              <img
                className="z-10 relative"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Rewards-Hero-main.png"
                alt="Vantage Rewards"
                width="600"
              />
            </picture>
          </div>
        </div>
      </section>
      <div class="popup-overlay modal fixed w-full h-full top-0 left-0 flex items-center justify-center">
        <div class="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
        <div class="popup-content modal-container bg-white w-11/12 md:max-w-md lg:max-w-6xl mx-auto rounded shadow-lg z-50 overflow-y-auto">
          <button class="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-white text-sm z-50">
            <svg
              class="fill-current text-white buttonClose"
              width="30"
              height="30"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
            </svg>
          </button>
          <div class="modal-content text-left">
            <div class="aspect-w-16 aspect-h-9">
              <iframe
                class="video"
                width="560"
                height="315"
                src=""
                data-src="https://www.youtube.com/embed/5Y_v1fXaPz0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Vantage Circle"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <section className="w-full py-10 xl:py-16" id="workforce">
        <div className="w-11/12 lg:max-w-7xl mx-auto">
          <h2 className="homepage-heading text-center">
            Unlock the power of effective <br />
            Rewards & Recognition!
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 place-content-center gap-3 mt-6 md:mt-10 xl:mt-16">
              <div className='p-4 md:p-4 flex justify-start items-start mt-7 lg:mt-0'>
                  <div className='w-1/3'>
                      <picture>
                          <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_260/q_auto/gatsbycms/uploads/2024/02/rewards-stats1.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="350" height="188" />
                          <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/02/rewards-stats1.webp" alt="Vantage Rewards" width="350" height="87" />
                      </picture>
                  </div>
                  <div className='w-2/3'><p className='text-gray-500 pl-2 p-0 m-0 pl-4'>cited recognition and rewards among the top factors for staying at a job</p></div>
              </div>
              <div className='p-4 m-0 md:p-4 flex justify-start items-start'>
                  <div className='w-1/3'>
                      <picture>
                          <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_260/q_auto/gatsbycms/uploads/2024/02/rewards-stats2.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="350" height="188" />
                          <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/02/rewards-stats2.webp" alt="Vantage Rewards" width="350" height="87" />
                      </picture>
                  </div>
                  <div className='w-2/3'><p className='text-gray-500 pl-2 p-0 m-0 pl-4'>higher employment engagement, productivity, and performance with recognition</p></div>
              </div>
              <div className='p-4 md:p-4 flex justify-start items-start'>
                  <div className='w-1/3'>
                      <picture>
                          <source className='z-10 relative' loading="lazy" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_260/q_auto/gatsbycms/uploads/2024/02/rewards-stats3.webp" media= "(min-width: 640px)" type="image/webp"alt="Vantage Rewards" width="350" height="188" />
                          <img className='z-10 relative' loading="lazy" src="https://res.cloudinary.com/vantagecircle/image/upload/w_121/q_auto/gatsbycms/uploads/2024/02/rewards-stats3.webp" alt="Vantage Rewards" width="350" height="87" />
                      </picture>
                  </div>
                  <div className='w-2/3'><p className='text-gray-500 pl-2 p-0 m-0 pl-4'>improvement in engagement can result in a 2% increase in margins</p></div>
              </div>
            
          </div>
        </div>
      </section>

    <section className="w-full">
      <div className="max-w-7xl mx-auto py-10 xl:pt-16 xl:pb-0">
        <div className="w-10/12 md:w-11/12 md:px-3 lg:px-0 lg:w-11/12 xl:w-full mx-auto xl:mx-0">
          <div class="grid grid-cols-1 lg:grid-cols-2 gap-10 justify-center items-start w-full mb-0 relative">
            <div class="w-full h-full flex flex-col text-center lg:text-left justify-center items-center lg:items-start lg:justify-start z-10">
              <p className="text-left p-0 m-0 pb-2">SOCIAL RECOGNITION</p>
              <h2 class="text-gray-250 homepage-heading text-center lg:text-left mb-3 w-full">
                Let your employees know they are{" "}
                <span className="text-orange">awesome</span>
              </h2>
              <p class="text-gray-250 text-center lg:text-left my-3 xl:my-2">
                Recognize the accomplishments of difference-makers and empower
                others to follow in their footsteps.
              </p>
              <div className="ml-6">
                <ul className="list-inside grayTick text-gray-250 lato text-lg md:pr-5 xl:px-0 w-full mt-4 md:mt-10 text-left">
                  <li className="py-1 text-gray-200 my-3">
                    <p className="m-0">
                      Foster a culture of appreciation with{/* */}{" "}
                      <span className="font-bold">
                        on-spot peer-to-peer monetary and non-monetary
                        recognition
                      </span>
                    </p>
                  </li>
                  <li className="py-1 text-gray-200 my-3">
                    <p className="m-0">
                      Recognize outstanding work with custom{/* */}{" "}
                      <span className="font-bold">
                        Awards &amp; Badges, Team Awards &amp; Panel Awards
                      </span>
                    </p>
                  </li>
                  <li className="py-1 text-gray-200 my-3">
                    <p className="m-0">
                      Stay connected and inspired through an interactive{/* */}{" "}
                      <span className="font-bold">Social Feed</span> and ignite
                      friendly competition with a{/* */}{" "}
                      <span className="font-bold">gamified Leaderboard</span>
                    </p>
                  </li>
                  <li className="py-1 text-gray-200 my-3">
                    <p className="m-0">
                      <span className="font-bold">
                        Pre-designed award programs
                      </span>{" "}
                      {/* */}that can be customized to meet the organization's
                      changing needs
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div class="w-full flex justify-center items-start z-10">
              <picture>
                <source
                  loading="lazy"
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Rewards-Social-recognition.png"
                  type="image/webp"
                  alt="vantage-rewards-social-recognition"
                  width="600"
                />
                <img
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Rewards-Social-recognition.png"
                  alt="vantage-rewards-social-recognition"
                  width="600"
                />
              </picture>
            </div>
          </div>
        </div>
      </div>
    

      <div className="w-full py-10 xl:py-16">
        <div className="w-11/12 lg:max-w-7xl mx-auto rewards-celebrate h-auto rounded-xl grid lg:grid-cols-2 items-center justify-center lg:justify-end p-3 lg:p-7">
          <div></div>
          <div className="bg-white py-10 px-5 lg:px-8 rounded-xl shadow-gray">
            <p className="text-left p-0 m-0">CELEBRATE</p>
            <h2 className="homepage-heading py-2 text-gray-500">
            Moments that <span className="text-orange">matter</span>
            </h2>
            <p className="text-gray-500 mb-6 md:my-5">
              Experience seamless automated celebrations to honor milestones &
              mark significant occasions of employees.
            </p>
            <div
              className="flex py-2"
              data-aos="fade-up"
              data-aos-duration="650"
              data-aos-delay="100"
            >
              <div className="w-1/12 lg:w-2/12">
                <picture>
                  <source
                    loading="lazy"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Group-637Overlay-Icons.png"
                    type="image/webp"
                    alt="company-celebrations"
                    width="45"
                  />
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Group-637Overlay-Icons.png"
                    alt="company-celebrations"
                    width="45"
                  />
                </picture>
              </div>
              <div className="w-10/12 lg:w-full">
                <p className="text-gray-500 pl-3 lg:pl-0">
                  Automated birthday & service anniversary wishes to never miss
                  a special moment
                </p>
              </div>
            </div>
            <div
              className="flex py-2"
              data-aos="fade-up"
              data-aos-duration="650"
              data-aos-delay="200"
            >
              <div className="w-1/12 lg:w-2/12">
                <picture>
                  <source
                    loading="lazy"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Group-638Overlay-Icons.png"
                    type="image/webp"
                    alt="company-celebrations"
                    width="45"
                  />
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Group-638Overlay-Icons.png"
                    alt="company-celebrations"
                    width="45"
                  />
                </picture>
              </div>
              <div className="w-10/12 lg:w-full">
                <p className="text-gray-500 pl-3 lg:pl-0">
                  Automated points awarding on birthdays and service
                  anniversaries
                </p>
              </div>
            </div>
            <div
              className="flex py-2"
              data-aos="fade-up"
              data-aos-duration="650"
              data-aos-delay="300"
            >
              <div className="w-1/12 lg:w-2/12">
                <picture>
                  <source
                    loading="lazy"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Group-639Overlay-Icons.png"
                    type="image/webp"
                    alt="company-celebrations"
                    width="45"
                  />
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Group-639Overlay-Icons.png"
                    alt="company-celebrations"
                    width="45"
                  />
                </picture>
              </div>
              <div className="w-10/12 lg:w-full">
                <p className="text-gray-500 pl-3 lg:pl-0">
                  Personalized Service Yearbook with work anniversary wishes
                  from colleagues
                </p>
              </div>
            </div>
            <div
              className="flex py-2"
              data-aos="fade-up"
              data-aos-duration="650"
              data-aos-delay="400"
            >
              <div className="w-1/12 lg:w-2/12">
                <picture>
                  <source
                    loading="lazy"
                    srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Group-640Overlay-Icons.png"
                    type="image/webp"
                    alt="company-celebrations"
                    width="50"
                  />
                  <img
                    loading="lazy"
                    src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/02/Group-640Overlay-Icons.png"
                    alt="company-celebrations"
                    width="50"
                  />
                </picture>
              </div>
              <div className="w-10/12 lg:w-full">
                <p className="text-gray-500 pl-3 lg:pl-0">
                  Exclusive digital Milestone Catalog sourced live through
                  Amazon for Long Service Awards
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> 


    <section className="w-full">
      <div className="w-full py-10 xl:py-16">
        <div className="w-11/12 xl:max-w-7xl mx-auto rounded-xl bg-gray-newGrayDark py-10 xl:pt-16 xl:pb-5">
          <p className="text-center p-0 m-0">DASHBOARD & ANALYTICS</p>
          <h2 class="homepage-heading py-3 text-center w-full">
            Making life<span class="text-orange"> easier</span> for HR.
          </h2>
          <p className="text-gray-500 py-3 text-center mx-auto w-11/12 lg:w-8/12">
            Save time and effort with seamless navigation & gain a bird's-eye
            view of the overall recognition program with real-time insights and
            downloadable reports for informed decision-making.
          </p>
          <div className="">
            <ul className="pl-8 pt-6 xl:pt-10 grayTick md:grid grid-cols-2 xl:grid-cols-4 gap-10 w-11/12 mx-auto justify-center">
              <li className="flex place-content-center">
                <p className="text-base">
                  Tailor the Recognition Program to fit your business needs with{" "}
                  <span className="font-bold">Configurations</span>
                </p>
              </li>
              <li className="xl:pl-2 flex place-content-center">
                <p className="text-base">
                  Streamline internal communication with company-wide{" "}
                  <span className="font-bold">Announcements</span>
                </p>
              </li>
              <li className="flex place-content-center">
                <p className="text-base">
                  Gain deeper visibility into key metrics with{" "}
                  <span className="font-bold">Manager Insights</span>
                </p>
              </li>
              <li className="flex place-content-center">
                <p className="text-base">
                  Optimize resource allocation for cost-effective HR decisions
                  with <span className="font-bold">Budget Management</span>
                </p>
              </li>
            </ul>
            <div
              className="pt-6 px-4"
              data-aos="fade-up"
              data-aos-duration="850"
              data-aos-delay="350"
            >
               <picture>
                <source
                  className="mx-auto my-3"
                  loading="lazy"
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_1400/q_auto/gatsbycms/uploads/2024/02/Rewards-Dashboardanalytics.png"
                  media="(min-width: 768px)"
                  type="image/webp"
                  alt="vantage-rewards-social-recognition"
                  width="1500"
                />
                <img
                  className="mx-auto mt-5"
                  loading="lazy"
                  src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Rewards-dashboard-mobile.png.webp"
                  srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2023/08/Rewards-dashboard-mobile.png.webp"
                  alt="vantage-rewards-social-recognition"
                  width="400"
                />
              </picture>
            </div>
          </div>
        </div>
        <div className="w-5/6 md:w-1/3 xl:w-1/5 2xl:w-1/6 mx-auto">
          <a
            href="#rewardsForm"
            className="vc-new-orange-btn-rounded-full text-white lato mx-auto rounded-full mt-10"
          >
            Book a product demo
          </a>
        </div>
      </div>

      <div
        class="mx-auto z-0 bg-indigo-100 flex flex-col place-content-center relative bg-indigo-100 overflow-hidden pt-9 lg:pt-0 xl:mt-16 xl:py-0 z-0 px-3 lg:px-3"
        id="amazonSection"
      >
        <div className="w-full lg:w-11/12 2xl:max-w-7xl mx-auto grid lg:grid-cols-2 justify-center items-center z-10 xl:pl-2 relative">
          <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 md:pt-10 lg:pt-12 lg:pb-16 xl:py-20">
            <picture>
              <source
                loading="lazy"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Amazon-business-logo.webp"
                type="image/webp"
                alt="company-celebrations"
                width="200"
              />
              <img
                loading="lazy"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Amazon-business-logo.png"
                alt="company-celebrations"
                width="200"
              />
            </picture>
            <h2 className="lg:pr-3 xl:w-10/12 text-3xl md:text-4xl xl:text-4xl text-center lg:text-left text-white mt-5 xl:py-3">
              10M+ products on our Amazon Store.{" "}
            </h2>
            <div className="xl:flex justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-2/3 xl:w-1/2 mx-auto lg:mx-0 cursor-pointer mt-8 z-10">
              <a
                className="vc-new-orange-border-btn-rounded-full text-orange lato rounded-full mb-3 mx-auto lg:mx-0 w-full xl:mb-0 xl:mr-2"
                href="/amazon-business-integrations/"
              >
                Know more
              </a>
            </div>
          </div>
          <div className="lg:absolute -bottom-72 md:-bottom-64 scale-90 lg:scale-75 transform xl:scale-100 md:right-5 lg:bottom-5 lg:-right-20 xl:bottom-0 xl:right-0 mx-auto mt-3 md:my-0 pt-4 md:pt-0 xl:mt-0 lg:ml-3">
            <picture>
              <source
                className="transform translate-y-4 md:translate-y-6 lg:translate-y-24 xl:translate-y-0"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_600/q_auto/gatsbycms/uploads/2024/02/amazon-business-monitor.webp"
                type="image/webp"
                alt="company-celebrations"
                width="500"
              />
              <img
                className="transform translate-y-4 md:translate-y-6 lg:translate-y-24 xl:translate-y-0"
                src="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2024/02/amazon-business-monitor.webp"
                alt="company-celebrations"
                width="300"
              />
            </picture>
          </div>
        </div>
        <div className="hidden xl:flex place-content-center flex-wrap rounded-full h-96 w-96 absolute -bottom-1/3 left-1/4 2xl:left-1/3 ml-20 2xl:ml-8" style={{ borderWidth: '0.8rem', borderColor: '#383861' }}>
          <div className="rounded-full h-72 w-72 flex place-content-center flex-wrap" style={{ borderWidth: '0.8rem', borderColor: '#383861' }}>
            <div className="rounded-full h-48 w-48 flex place-content-center flex-wrap" style={{ borderWidth: '0.8rem', borderColor: '#383861' }}></div>
          </div>
        </div>
        <div className="hidden xl:flex place-content-center flex-wrap rounded-full border-purple-lighterpurple h-96 w-96 absolute -top-1/2 right-60 2xl:right-1/4 ml-20 2xl:ml-8" style={{ borderWidth: '0.8rem', borderColor: '#383861' }}>
          <div className="rounded-full h-72 w-72 flex place-content-center flex-wrap" style={{ borderWidth: '0.8rem', borderColor: '#383861' }}>
            <div className="rounded-full h-48 w-48 flex place-content-center flex-wrap" style={{ borderWidth: '0.8rem', borderColor: '#383861' }}></div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-10"><RewardCatalogSlideUp rewardPointProp = {rewardPointProp} /></section>

    <section className="w-full">
      <div className="bg-purple-100 py-10 xl:py-20 w-full z-10 relative">
        <div className="w-11/12 xl:max-w-7xl mx-auto bg-white rounded-xl"></div>
        {/* <div id="flipperID"></div> */}
        <Flipper contents = {contents} />
      </div>

      <div class="py-10 xl:pt-16 px-4">
        <div class="w-full grid justify-center">
          <p className="text-center p-0 pb-2 m-0">INTEGRATION</p>
          <h2 class="homepage-heading w-11/12 md:w-10/12 lg:max-w-5xl text-center pb-10 xl:pb-16 lg:tracking-wide mx-auto">
            <span class="text-orange">Seamless Integration </span> to make
            rewarding a breeze
          </h2>
          <div class="hidden lg:grid grid-cols-3 mx-auto w-10/12 mt-5">
            <p class="mx-auto text-gray-500 text-lg">Social Integrations</p>
            <p class="mx-auto text-gray-500 text-lg">SSO Integrations</p>
            <p class="mx-auto text-gray-500 text-lg">HRIS Integrations</p>
          </div>
          <div class="grid grid-cols-1 lg:grid-cols-3 mx-auto xl:w-10/12 my-5">
            <div class="lg:border-r px-5">
              <p class="mx-auto text-gray-500 text-lg lg:hidden pb-6 text-center">
                Social Integrations
              </p>
              <div class="grid grid-cols-3 gap-5">
                <div class="intLogo mx-auto" id="logo1"></div>
                <div class="intLogo mx-auto" id="logo2"></div>
                <div class="intLogo mx-auto" id="logo3"></div>
                <div class="intLogo mx-auto" id="logo4"></div>
                <div class="intLogo mx-auto" id="logo5"></div>
                <div class="intLogo mx-auto" id="logo6"></div>
              </div>
            </div>
            <div class="lg:border-r px-5 pt-5 lg:pt-0 lg:pl-9">
              <p class="mx-auto text-gray-500 lg:hidden pt-10 text-lg pb-6 text-center">
                SSO Integrations
              </p>
              <div class="flex xl:grid justify-center gap-5 pl-5 lg:pl-0">
                <div class="intLogo mx-auto" id="logo7"></div>
                <div class="intLogo mx-auto" id="logo8"></div>
              </div>
            </div>
            <div class="px-5 pt-5 lg:pt-0">
              <p class="mx-auto text-gray-500 lg:hidden pt-10 text-lg pb-6 text-center">
                HRIS Integrations
              </p>
              <div class="grid grid-cols-3 gap-5 lg:pl-9">
                <div class="intLogo mx-auto" id="logo9"></div>
                <div class="intLogo mx-auto" id="logo10"></div>
                <div class="intLogo mx-auto" id="logo11"></div>
                <div class="intLogo mx-auto" id="logo12"></div>
                <div class="intLogo mx-auto" id="logo13"></div>
              </div>
            </div>
          </div>
          <div class="max-w-7xl mx-auto grid grid-cols-1 gap-5 lg:gap-10 mt-10 mb-2">
            <a
              href="/integration/"
              target="_blank"
              class="text-orange"
              rel="noopener"
            >
              Explore all integrations
            </a>
          </div>
        </div>
      </div>
    </section>  

      <section class="mx-auto z-0 flex flex-col place-content-center relative overflow-hidden lg:overflow-visible py-10 xl:py-16 z-0 lg:px-3">
        <div className="w-11/12 xl:max-w-7xl bgMicrosoft mx-auto grid lg:grid-cols-2 justify-center items-center z-10 relative p-8 rounded-xl">
          <div className="w-full md:w-11/12 lg:w-full mx-auto flex flex-col text-center lg:text-left justify-center lg:items-start items-center xl:mr-3 xl:pt-12 xl:pb-20">
            <picture>
              <source
                className="z-0 mt-5 mb-2"
                loading="lazy"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/microsoft-teams-logo-2-1-1.webp"
                type="image/webp"
                alt="msteams"
                width="200"
              />
              <img
                className="z-0 mt-5 mb-2"
                loading="lazy"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/microsoft-teams-logo-2-1-1.png"
                alt="msteams"
                width="200"
              />
            </picture>
            <h2 className="lg:pr-3 pt-4 md:pt-5 xl:pt-3 xl:w-full text-3xl md:text-4xl xl:text-5xl text-center lg:text-left">
              Recognize while collaborating on Teams
            </h2>
            <div className="xl:flex justify-center items-center lg:justify-start w-5/6 md:w-1/2 lg:w-2/3 xl:w-1/2 cursor-pointer my-8 z-10">
              <a
                className="vc-new-orange-border-btn-rounded-full lato rounded-full mb-3 w-full xl:mb-0 xl:mr-2 text-orange"
                href="/integration/microsoft-teams/"
              >
                Know more
              </a>
            </div>
          </div>
          <div className="hidden xl:block absolute h-72 w-72 -top-0  -right-0 overflow-hidden rounded-xl">
            <div className="relative h-full w-full">
              <div className="h-80 w-80 rounded-full absolute -top-20 -right-20 z-0" style={{ background: '#cdcde4' }}></div>
            </div>
          </div>
          <div className="lg:absolute -bottom-72 md:-bottom-64 md:scale-90 lg:scale-75 transform xl:scale-100 md:right-5 lg:-bottom-24 lg:-right-20 xl:-bottom-16 xl:right-6 flex justify-center items-center h-full py-4 md:mt-0 md:pt-0 lg:ml-3">
            <picture>
              <source
                loading="lazy"
                srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/Rewards-MSTEams.webp"
                type="image/webp"
                alt="msteams"
                width="700"
              />
              <img
                loading="lazy"
                src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/07/Rewards-MSTEams.png"
                alt="msteams"
                width="700"
              />
            </picture>
          </div>
        </div>
      </section>

    <section class="w-full flex place-content-center overflow-x-hidden py-10 xl:py-16 overflow-y-hidden px-6 lg:px-0">
      <div class="xl:flex lg:w-11/12 xl:max-w-7xl mx-auto bg-indigo-100 overflow-hidden rounded-xl px-0">
          <div class="card-1 w-full p-5 pb-0 md:px-7 md:pt-7 lg:pl-8 xl:p-7 xl:pl-12 md:mb-0 xl:mt-0 xl:w-7/12 overflow-hidden relative md:flex">
              <div className='md:w-1/2 lg:w-full lg:my-auto'>     
                  <h2 class="text-3xl lg:text-4xl mx-auto md:mx-0 text-center md:text-left text-purple-100 pt-5 xl:pt-0 mb-2 xl:mb-2 lg:px-0 lg:w-10/12 xl:w-11/12 2xl:w-10/12">Make your employees <span className='text-orange'>love their job</span> a little more, everyday</h2>
                  <p className='lg:w-11/12 mx-auto lg:mx-0 text-center md:text-left text-purple-100 lg:pl-1 m-0 pt-4'>Highly rated on:</p>
                  <div className='lg:grid mt-3 md:justify-around lg:justify-start lg:items-center'>
                    <div
                      className="flex lg:place-content-center xl:justify-start lg:mt-3 xl:mt-0"
                      data-aos="fade-right"
                      data-aos-duration="650"
                      data-aos-delay="100"
                      id="rewardsForm"
                    >
                      <picture>
                        <source
                          className="my-3"
                          loading="lazy"
                          srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/G2-Rating-new-badge.webp"
                          type="image/webp"
                          alt="G2-rating"
                          width="200"
                        />
                        <img
                          loading="lazy"
                          className="my-3"
                          src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/G2-Rating-new-badge.png"
                          alt="G2-rating"
                          width="200"
                        />
                      </picture>
                    </div>
                  </div>
                  <div
                    className="flex lg:place-content-center xl:justify-start lg:mt-3 xl:mt-0"
                    data-aos="fade-right"
                    data-aos-duration="650"
                    data-aos-delay="200"
                  >
                    <picture>
                      <source
                        className="my-3"
                        loading="lazy"
                        srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Getapp-Rating-new-badge.webp"
                        type="image/webp"
                        alt="GetApp"
                        width="200"
                      />
                      <img
                        loading="lazy"
                        className="my-3"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Getapp-Rating-new-badge.png"
                        alt="GetApp"
                        width="200"
                      />
                    </picture>
                  </div>
                  <div
                    className="flex lg:place-content-center xl:justify-start lg:mt-3 xl:mt-0"
                    data-aos="fade-right"
                    data-aos-duration="650"
                    data-aos-delay="300"
                  >
                    <picture>
                      <source
                        className="my-3"
                        loading="lazy"
                        srcset="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Capterra-Rating-new-badge.png"
                        type="image/webp"
                        alt="Capterra"
                        width="200"
                      />
                      <img
                        loading="lazy"
                        className="my-3"
                        src="https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2023/08/Capterra-Rating-new-badge.png"
                        alt="Capterra"
                        width="200"
                      />
                    </picture>
                  </div>
              </div>
              <div className='flex md:w-1/2 lg:w-1/3 place-content-center md:justify-end lg:hidden xl:block xl:absolute right-5 -bottom-12' style={{ zIndex: '5' }}>
                  <picture>
                      <source className="mt-3 transform scale-90 xl:scale-100 origin-bottom" srcset="https://res.cloudinary.com/vantagecircle/image/upload/w_400/q_auto/gatsbycms/uploads/2024/03/perks04-1.webp" media="(min-width: 640px)" type="image/webp" alt="Capterra" width="300" height="456" />
                      <img className='mt-3 transform scale-90 xl:scale-100 origin-bottom' src="https://res.cloudinary.com/vantagecircle/image/upload/w_280/q_auto/gatsbycms/uploads/2024/03/perks04-1.webp" alt="Capterra" width="280" height="441" />
                  </picture>
              </div>
          </div> 
        <div className='w-full xl:w-1/2 pt-0 px-4 pb-8 lg:p-8'>
            <div class="card-1 md:w-5/6 xl:w-full mx-auto rnrForm xl:mt-0 flex flex-col place-items-center px-4 lg:px-6 py-7 lg:py-10 bg-indigo-50 frostedTransparentBG rounded-xl z-10 relative" style={{borderWidth: "0.3px", borderColor: "#ddd" }} id="form">
                {/* <h2 class="text-2xl lato text-purple-100 pt-5 mb-8 md:px-12 lg:px-0 text-center">You are now one step away from making your employees happy</h2> */}
                <div class="w-full flex justify-center items-center lato rewards team">
                    <div class="flex justify-center items-center">
                        <HubspotForm
                        portalId="6153052"
                        formId="d2e2c7bf-c255-4b72-a236-53240ebeed17"
                        onSubmit={() => console.log("Submit!")}
                        onReady={form => console.log("Form ready!")}
                        loading={<div>Loading...</div>}
                        />
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
    </Layout>
  )
}

export default RewardsRecognition
